import Vue from 'vue'
import App from './App.vue'
import 'normalize.css/normalize.css'
import 'lib-flexible/flexible'
import component from'./components/index'
import VantUIPlugin from './ui'
import router from './router'
Vue.config.productionTip = false
Vue.use(VantUIPlugin)
Vue.use(component); 
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
