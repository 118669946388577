import {
    NavBar, Switch, Cell, CellGroup, Grid, GridItem, Button, Search, Tab, Tabs, Image as VanImage, Divider, Swipe, SwipeItem, Icon, Empty, Dialog
} from 'vant';


const UIComponents = [
    NavBar, Switch, Cell, CellGroup, Grid, GridItem, Button, Search, Tab, Tabs, VanImage, Divider, Swipe, SwipeItem, Icon, Empty, Dialog]

const VantUIPlugin = {};

VantUIPlugin.install = function (app) {
    UIComponents.forEach(Component => {
        app.use(Component)
    })
}

export default VantUIPlugin