import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        title: '首页',
        path: '/',
        name: 'ShowPictureByCode',
        component: () => import('../views/ShowPictureByCode/index.vue')
    },
    {

        title: '扫码页面',
        name: 'ScanCodePage',
        path: '/ScanCodePage',
        component: () => import('../views/ScanCodePage/index.vue')

    }
];
const router = new VueRouter({
    routes
})
export default router