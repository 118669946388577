<template>
  <div class="custom-swiper">
    <!-- 轮播 -->
    <van-swipe v-if="ImageList && ImageList.length > 0" @change="onChange">
      <van-swipe-item v-for="item in ImageList" :key="item.image_id">
        <div class="box">
          <img class="img" :src="item.image_src" alt="预览图片" />
        </div>
        <van-grid
          direction="horizontal"
          :column-num="TabType == '无尺英文' ? 3 : 2"
        >
          <van-grid-item
            v-if="TabType == '无尺英文'"
            icon="setting-o"
            text="设为主图"
          />
          <van-grid-item icon="delete-o" text="删除" />
          <van-grid-item icon="edit" text="抠图" />
        </van-grid>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
          {{ current + 1 }}/{{ ImageList.length }}
        </div>
      </template>
    </van-swipe>
    <!-- 无数据 -->
    <van-empty v-else description="暂无图片资源" />
    <!-- 拍照 -->
    <div class="scan-btn">
      <van-button type="info" size="large"> {{ TabType }}-拍照 </van-button>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  name: "CustomSwiper",
  props: {
    TabType: {
      type: String,
    },
    ImageList: {
      type: [Array || Object],
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    changeMainPicture() {
      Dialog.confirm({
        title: "产品拍照",
        message: "该产品已设置为主图，请跟工厂确认是否更换?",
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style lang="less" scoped>
.custom-swiper {
  width: 100%;

  .van-button {
    border: none;
  }

  .block {
    width: 100%;
    height: 10px;
    background-color: #f7f8fa;
  }

  .custom-indicator {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }

  .btn-group {
    width: 99%;
    height: 50px;
    margin: 0 auto;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .divider {
      width: 10px;
      height: 100%;
      background-color: #f7f8fa;
    }
  }

  .box {
    width: 100%;

    .img {
      width: 100%;
      height: 300px;
      display: block;
      object-fit: cover;
    }
  }

  .van-empty {
    width: 100%;
    height: 300px;
  }

  .scan-btn {
    width: 99%;
    margin: 0 auto;
    margin-top: 5px;
  }
}
</style>
